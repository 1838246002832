<template>
    <div class="full-size">
        <div>
            <b-g-decorate></b-g-decorate>
            <el-image class="register-header-image" :src="require('@images/logo-big.png')"/>
        </div>
        <div class="register-form">
            <el-form ref="el-form" :model="formData" :rules="rules" label-position="top" :status-icon="true"
                     :inline-message="true" :show-message="false">
                <el-card class="register-card">
                    <div class="card-label">
                        <svg-icon class-name="label-icon" icon="icon_miantiao" width="58" height="32"/>
                        <span class="label-title">注册</span>
                    </div>
                    <el-form-item prop="mobile" label="手机号">
                        <el-input v-model="formData.mobile" placeholder="请输入手机号"></el-input>
                    </el-form-item>
                    <el-form-item prop="password" label="密码">
                        <el-input v-model="formData.password" placeholder="请输入密码(6-8位字母数字组合)" type="password"></el-input>
                    </el-form-item>
                    <el-form-item prop="validCode" label="验证码">
                        <el-row justify="space-between" type="flex">
                            <div class="input_ver_code">
                                <el-input v-model="formData.validCode" placeholder="请输入验证码"/>
                            </div>
                            <span v-if="times >= 60" class="btn-send-code" @click="sendValidCode">获取验证码</span>
                            <span v-if="times < 60" class="btn-send-code">请等待{{times}}秒</span>
                        </el-row>
                    </el-form-item>
                    <el-form-item prop="isCheckProtocol" class="layout-protocol">
                        <el-checkbox v-model="formData.isCheckProtocol" class="protocol-check" checked></el-checkbox>
                        登陆即代表同意<span class="protocol-text" @click="toProtocolPage">《注册协议》</span>
                    </el-form-item>
                </el-card>

                <el-button class="submit-button" @click="toRegister">注册</el-button>
                <el-button class="cancel-button" @click="_goBack()">返回</el-button>
            </el-form>
        </div>
    </div>
</template>

<script>
    import BGDecorate from "@/components/BGDecorate";

    export default {
        name: "RegisterPage",
        components: {BGDecorate},
        data() {
            return {
                //计时工具
                timer: null,
                times: 60,
                //表单数据
                formData: {
                    mobile: "",
                    password: "",
                    validCode: "",
                    isCheckProtocol: false,
                },
                rules: {
                    mobile: [
                        {required: true, message: '请输入手机号', trigger: 'blur'},
                        {len: 11, message: '请检查手机号长度', trigger: 'blur'}
                    ],
                    password: [
                        {required: true, message: '请输入密码', trigger: 'blur'},
                        {
                            pattern: /^(?=.*\d)(?=.*[a-zA-Z])[\da-zA-Z~!@#$%^&*]{6,16}$/,
                            message: '密码长度需为6-8位字母数字组合',
                            trigger: 'blur'
                        }
                    ],
                    validCode: [
                        {required: true, message: '请输入验证码', trigger: 'blur'},
                        {len: 6, message: '请检查验证码长度', trigger: 'blur'}
                    ],
                    isCheckProtocol: [
                        {
                            type: 'boolean',
                            required: true, message: '请阅读并同意《注册协议》', validator: function (rule, value, callback) {
                                if (!value) {
                                    callback(new Error('请阅读并同意《注册协议》'));
                                }
                                callback();
                            },
                        },
                    ]
                }
            }
        },
        methods: {
            toRegister() {
                let _this = this;
                this.$refs["el-form"].validate((valid, error) => {
                    if (valid) {
                        _this.$api.register({
                            phoneNo: _this.formData.mobile,
                            password: _this.formData.password,
                            validCode: _this.formData.validCode,
                        }).then(res => {
                            console.log("注册成功:", res)
                            _this.$notify.success("注册成功")
                            _this._goBack();
                        }).catch(err => {
                            _this.$notify.error(err.message)
                        })
                    } else {
                        this._showErrorValidMsg(error)
                    }
                });
            },
            sendValidCode() {
                let _this = this;
                _this.$refs["el-form"].validateField('mobile', (msg) => {
                    if (!msg) {
                        //发送验证码
                        _this.$api.sendValidCode({phoneNo: _this.formData.mobile, funcType: "USER_REGISTER"})
                            .then(() => {
                                console.log("请求成功")
                                //显示倒计时
                                _this.timer = setInterval(() => {
                                    _this.times--
                                    if (_this.times <= 0) {
                                        _this.times = 60
                                        clearInterval(_this.timer)
                                    }
                                }, 1000)
                            }).catch(err => {
                            _this.$notify.error(err.message)
                        })
                    } else {
                        _this.$notify.error(msg)
                    }
                })
            },
            toProtocolPage() {
                this.$router.push({
                    path: '/protocol', query: {
                        type: 'register'
                    }
                });
            }
        }
    }
</script>

<style scoped lang="less">

    /*头部*/
    .register-header-image {
        width: 80%;
        margin-top: 10%;
    }

    /*表单div*/
    .register-form {
        margin-top: 10%;

        .layout-protocol {
            color: @font-desc;
            font-size: 10px;

            /deep/ .el-checkbox__inner {
                border-color: @color-main;
                background-color: @color-main;
            }

            .protocol-text {
                color: @color-main;
            }
        }
    }

    /*表单*/
    .register-card {
        margin: 0px 34px;
        background: #FFFFFF;
        box-shadow: 3px 3px 50px #D6DBDE;
        border-radius: 24px;
        text-align: left;

        /*标题*/

        .card-label {
            position: relative;

            .label-title {
                position: absolute;
                font-family: Roboto;
                font-style: normal;
                font-weight: normal;
                font-size: 18px;
                line-height: 21px;
                left: 10px;
                top: 5px;
            }

            .label-icon {
                position: relative;
                left: 0px;
            }
        }

        /*验证码*/

        .btn-send-code {
            font-family: Roboto;
            font-style: normal;
            font-weight: normal;
            /* identical to box height */
            text-decoration-line: underline;
            color: #FF9534;
            font-size: 10px;
            white-space: nowrap;
            margin-left: 10px;
        }

        .input_ver_code {
            display: flex;
            flex-direction: row;
            margin-bottom: 10px;
            font-size: 12px;

            /*/deep/ .el-input {*/
            /*    width: auto;*/
            /*    margin-right: 5px;*/
            /*}*/

            /*/deep/ .el-input__inner {*/
            /*    width: 30px;*/
            /*    height: 30px;*/
            /*}*/
        }
    }


</style>
